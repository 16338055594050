const tdClass = 'align-middle';

export default [
  { key: 'name', i18nKey: 'issuerTexts.label.table.name', tdClass },
  { key: 'type', i18nKey: 'issuerTexts.label.table.type', tdClass },
  { key: 'isHtml', i18nKey: 'issuerTexts.label.table.isHtml', tdClass },
  { key: 'active', i18nKey: 'issuerTexts.label.table.active', tdClass },
  { key: 'value', i18nKey: 'issuerTexts.label.table.value', tdClass },
  { key: 'actions', label: ' ', tdClass: `text-nowrap ${tdClass} text-center` },
];
