<template>
  <div>
    <cp-head-table
      :create="false"
      :title="$t('issuerTexts.title')"
      title-icon="ios-build"
    />
    <cp-table
      ref="cpTable"
      get-data-action="issuerTexts/getIssuerTexts"
      :url-params="urlParams"
      :fields="fields"
    >
      <template
        slot="type"
        slot-scope="{ rowData }"
      >
        <b-badge
          variant="outline-info"
        >
          {{ $t(`issuerTexts.status.${rowData.item.type}`) }}
        </b-badge>
      </template>
      <template
        slot="active"
        slot-scope="{ rowData }"
      >
        <span
          class="ion"
          :class="{ 'ion-md-checkmark text-primary': rowData.item.active, 'ion-md-close text-light': !rowData.item.active }"
        />
      </template>
      <template
        slot="isHtml"
        slot-scope="{ rowData }"
      >
        <span
          class="ion"
          :class="{ 'ion-md-checkmark text-primary': rowData.item.isHtml, 'ion-md-close text-light': !rowData.item.isHtml }"
        />
      </template>

      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <b-btn
          v-b-tooltip.hover="true"
          class="mr-3"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          :title="$t('common.button.edit')"
          @click="toEditModal(rowData.item)"
        >
          <i
            class="ion ion-md-create"
            :style="{ color: '#04bec4' }"
          />
          {{ $t('common.button.edit') }}
        </b-btn>
      </template>
    </cp-table>

    <cp-text-modal
      ref="issuerTextModal"
      :modal-data="modalData"
      @onSubmit="saveChanges"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import CpTable from '~/components/shared/cp-table';
import CpHeadTable from '~/components/common/head';
import CpTextModal from './issuer-text-modal';

import fields from './fields';

export default {
  name: 'IssuerTexts',

  metaInfo: {
    title: 'Issuer Texts',
  },

  components: {
    CpTable,
    CpHeadTable,
    CpTextModal,
  },

  data() {
    return {
      modalData: {},
      fields,
      urlParams: { issuerId: this.$route.params.idIssuer },
    };
  },

  methods: {
    ...mapActions('issuerTexts', ['update']),

    toEditModal(item) {
      this.modalData = item;
      this.$refs.issuerTextModal.$refs.issuerText.show();
    },

    saveChanges(text) {
      this.update({
        issuerId: this.$route.params.idIssuer,
        text,
      }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.$refs.issuerTextModal.$refs.issuerText.hide();
      });
    },
  },
};
</script>
